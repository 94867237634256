import { ExternalLink } from '@styled-icons/evil';
import { Icon, IconProps } from './Icon';

export function ExternalLinkIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <ExternalLink />
    </Icon>
  );
}

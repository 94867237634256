import { Location } from '@styled-icons/evil';
import { Icon, IconProps } from './Icon';

export function LocationIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Location />
    </Icon>
  );
}
